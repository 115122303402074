<template>
    <div :class="['block block-generic-text', (bgColor) ? bgColor : '']" >
        <div class="rte">
            <div class="inner" data-aos="custom-fade-in" >
                <h2 v-if="json.title" v-html="json.title"></h2>
                <div class="under-title" v-if="json.body" v-html="json.body"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "GenericText",
    data () {
        return {

        }
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
        bgColor: {
            type: String,
            required: false,
            default: '',
        },
    },

}
</script>