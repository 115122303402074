<template>
    <section v-if="json" class="page-generic">
        <GenericText
            bgColor="bg-purple-dk"
            :json="json"
        ></GenericText>
    </section>
</template>
<script>
import axios from 'axios'
import GenericText from '@/components/blocks/GenericText'
import gh from '@/helpers/gh'
export default {
    name: "Generic",
    components: {
        GenericText,
    },
    data () {
        return {
            window: window,
            json: null,
            transition: false,
        }

    },
    created () {
    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        axios.get((`${window.config.apiroot}generic${window.config.apiext}/?slug=${ slug }`))
            .then((res) => {
                this.json = res.data
                this.reveal()
            }).catch((e) => {
                console.error('Error',slug,e)
        })
        this.reveal()
    },
    methods: {
        reveal () {

        },
        unreveal () {

        },
    }
}
</script>