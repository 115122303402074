import {createApp} from 'vue'
import App from './App.vue'
import axios from 'axios'

//globals
import InlineSvg from 'vue-inline-svg'

//state
import {store} from './store'

//routes
import VueGtag from "vue-gtag-next"
import router from './router'
import Home from "@/components/pages/Home"
import Generic from "@/components/pages/Generic"
import Promos from "@/components/pages/Promos"


//GLOBAL CONFIG
window.config = {
    loadedOnce: false,
    routerInTransition: false,
    apiroot: window.apiroot,
    apiext: (window.apiroot === '/local-temp/api/') ? '.json' : '',
    json: {
        global: null
    },
}
axios.get((`${window.config.apiroot}global${window.config.apiext}`))
    .then((res) => {
        //GLOBAL CONFIG
        window.config.json.global = res.data

        //DYNAMIC ROUTES
        for (let i = 0; i < res.data.routes.length; i++) {
            let obj = res.data.routes[i]
            let newobj = {
                path: obj.path,
                meta: {
                    title: obj.title
                },
            }
            switch (obj.type) {
                case 'home':
                    newobj.component = Home
                    break
                case 'generic':
                    newobj.component = Generic
                    break
                case 'promos':
                    newobj.component = Promos
                    break
            }
            router.addRoute(newobj)
        }
        //page not found
        let newobj = {
            name: 'page-not-found',
            path: "/:catchAll(.*)",
            //component: PageNotFound,
            meta: {
                title: '404'
            }
        }
        router.addRoute(newobj)

        //START APP
        startApp()
    }).catch(() => {
})

function startApp () {
    //LOAD
    const app = createApp(App)
    app.use(router)
    app.use(store)


    //ANALYTICS - in your main.js, below loading your router
    if (window.location.hostname !== "localhost") {
        app.use(VueGtag, {
            property: {
                id: "G-1W7JLWK1CR"
            }
        })
    }

    //global inline svg
    app.component('inline-svg', InlineSvg)

    app.mount('#app')
}

