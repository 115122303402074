<template>
    <div v-if="json" class="block block-locations">
        <GenericText
            bgColor="bg-white"
            :json="json.rte"
        ></GenericText>
        <div class="capper">
            <div class="capper-padded">
                <div class="locations-sections">
                    <div
                        v-for="(item, index) in json.sections"
                        :key="`locations-section-${index}`"
                        :class="['locations-section',item.width]"
                    >
                        <div :class="[`title`,`bg-color-${item.titleBgColor}`]" data-aos="custom-fade-in">
                            <div class="rte">
                                <h5>{{item.title}}</h5>
                            </div>
                        </div>
                        <div class="icons">
                            <div
                                v-for="(item2, index2) in item.items"
                                :key="`icon-${index}-${index2}`"
                                class="icon"
                                data-aos="custom-fade-in"
                                @click="openPopup(cloneDeep(item2))"
                            >
                                <div class="bg">
                                    <div class="bgimg" :style="`background-image: url(${item2.icon})`"></div>
                                </div>
                                <div class="num">{{item2.mapLegendKey}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="locations-map" data-aos="custom-fade-in">
                    <img :src="json.mapImage"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import GenericText from '@/components/blocks/GenericText.vue'
import {cloneDeep} from 'lodash'
export default {
    name: "Locations",
    components: {
        GenericText
    },
    data () {
        return {
            window: window,
            json: null,
            inPanelTransition: false,
            cloneDeep:cloneDeep,
        }

    },
    created () {

    },
    mounted () {
        axios.get((`${window.config.apiroot}locations${window.config.apiext}`))
            .then((res) => {
                this.json = res.data
            }).catch(() => {
        })
    },
    methods: {
        openPopup (obj) {
            this.$store.commit('openPopup', obj)
        },
    }
}
</script>