<template>
    <div class="featured-simple">
        <div class="capper">
            <img class="bg" :src="featuredImage"/>
            <div class="title"><div class="rte"><h1 v-html="title"></h1></div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FeaturedSimple",
    props:{
        featuredImage: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    }
}
</script>