<template>
    <div v-if="json" class="block block-ctas">
        <div class="capper">
            <div class="ctas">
                <div
                    v-for="(item, index) in json"
                    :key="`ctas-${index}`"
                    class="cta"

                    data-aos="custom-fade-in"
                >
                    <div class="show-desktop">
                        <router-link v-if="item.target==='_self'" class="generic-image-hover" :to="item.href">
                            <img :src="item.img"/>
                        </router-link>
                        <a v-else class="generic-image-hover" :href="item.href" :target="item.target">
                            <img :src="item.img"/>
                        </a>
                    </div>
                    <div class="show-mobile">
                        <router-link v-if="item.target==='_self'" class="generic-image-hover" :to="item.href">
                            <img :src="(item.imgMobile) ? item.imgMobile : item.img"/>
                        </router-link>
                        <a v-else class="generic-image-hover" :href="item.href" :target="item.target">
                            <img :src="(item.imgMobile) ? item.imgMobile : item.img"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CTAs",
    data () {
        return {

        }
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
    },
}
</script>