<template>
    <router-link
        v-if="target === '_self'"
        :to="href"
        :class="classes"
    >{{label}}</router-link>
    <a v-else :class="classes" :href="href" :target="target">{{label}}</a>
</template>

<script>
export default {
    name: "Link",
    props: {
        href: {
            type: String,
            required: true,
            default: '',
        },
        target: {
            type: String,
            required: true,
            default: '',
        },
        label: {
            type: String,
            required: true,
            default: '',
        },
        classes: {
            type: String,
            required: false,
            default: '',
        },
    },
}
</script>