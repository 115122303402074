<template>
    <div class="capper">
        <footer id="footer" v-if="json">
            <div class="inner">
                <div class="capper-padded">
                    <div class="ctas">
                        <div class="flex-content">
                            <div class="l">
                                <router-link class="generic-image-hover" to="/">
                                    <img src="@/assets/images/global/logo-footer.png"/>
                                </router-link>
                            </div>
                            <div class="r">
                                <div class="l1">
                                    <div class="rte color-theme-light">
                                        <p>FIND US</p>
                                    </div>
                                </div>
                                <a class="generic-image-hover" :href="json.links.googleMapLink" target="_blank">
                                    <img src="@/assets/images/global/footer-map.jpg"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="legal">
                        <div class="rte color-theme-light no-max-width">
                            <div class="flex-content">
                                <div class="l">
                                    <p>© 2020 | Tejon Ranch Commerce Center. | 661.248.3000</p>
                                </div>
                                <div class="r">
                                    <p>Headquarters: 4436 Lebec Road | Tejon Ranch, CA 93243</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-nav" v-show="json.footer?.nav">
                        <nav>
                            <ul>
                                <li
                                    v-for="(item, index) in json.footer.nav"
                                    :key="`footer-nav-${index}`"
                                >
                                    <Link
                                        :href="item.href"
                                        :target="item.target"
                                        :label="item.label"
                                        classes="link-underline-leftright white small"
                                    />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
import Link from '@/components/pieces/Link'
export default {
    name: "Footer",
    components: {
        Link
    },
    data () {
        return {
            window: window,
            json: null,
        }
    },
    mounted () {
        this.json = this.window.config.json.global
    },
}
</script>