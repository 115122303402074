<template>
    <div id="container">
        <Header/>
        <div id="main">
            <router-view :key="$route.fullPath"></router-view>
        </div>
        <Footer/>
        <Popup/>
        <TransitionOverlay></TransitionOverlay>
    </div>
</template>

<script>
import Header from './components/framework/Header'
import Footer from './components/framework/Footer'
import Popup from './components/framework/Popup'
import TransitionOverlay from './components/framework/TransitionOverlay'

export default {
    name: 'App',
    components: {
        Header,
        Footer,
        Popup,
        TransitionOverlay
    },
    mounted() {

    }
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>