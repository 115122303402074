{% import 'macros/helpers' as helpers %}
<template>
    <div v-show="popupOpen" id="popup">
        <div id="popup-scrim" @click="closePopup"></div>
        <div id="popup-inner">
            <div @click="closePopup" class="close-button"></div>
            <div class="top-image">
                <div :class="['bgimg-outer', (!obj?.feateredImage) ? 'no-image' : '' ]">
                    <div v-if="obj?.feateredImage" :style="'background-image:url('+obj.feateredImage+');'" class="bgimg"></div>
                </div>
                <div class="top-image-scrim"></div>
                <div class="top-image-title">
                    <div class="rte">
                        <h3 v-if="obj?.title">{{obj?.title}}</h3>
                    </div>
                </div>
            </div>
            <div class="content-inner">
                <div class="rte">
                    <div class="popup-columns">
                        <div class="col col-left">
                            <div class="rows">
                                <div class="row" v-if="obj?.phone">
                                    <div class="l">
                                        <img src="@/assets/images/global/icon-phone.png" />
                                    </div>
                                    <div class="r">
                                        <p>
                                            <a class="link-underline-leftright dark" :href="`tel:${obj?.phone}`" target="_blank">{{obj?.phone}}</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="row" v-if="obj?.address1">
                                    <div class="l">
                                        <img src="@/assets/images/global/icon-map.png" />
                                    </div>
                                    <div class="r">
                                        <p>
                                            <a class="link-underline-leftright dark" :href="addressLink" target="_blank">{{obj?.address1}}
                                                <br v-if="obj?.address2">{{obj?.address2}}
                                                <br>{{obj?.city}}, {{obj?.state}}, {{obj?.zip}}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div class="row" v-if="obj?.website">
                                    <div class="l">
                                        <img src="@/assets/images/global/icon-web.png" />
                                    </div>
                                    <div class="r">
                                        <p>
                                            <a class="link-underline-leftright dark" :href="obj?.website" target="_blank">Website</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="row" v-if="obj?.yelp">
                                    <div class="l">
                                        <img src="@/assets/images/global/icon-yelp.png" />
                                    </div>
                                    <div class="r">
                                        <p>
                                            <a class="link-underline-leftright dark" :href="obj?.yelp" target="_blank">Yelp</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col-right">
                            <div class="socicons">
                                <a v-if="obj?.fb" title="Facebook" class="socicon_a link-underline-leftright dark" :href="obj?.fb" target="_blank">
                                    <span class="socicon socicon-facebook"></span>
                                </a>
                                <a v-if="obj?.twitter" title="Twitter" class="socicon_a  link-underline-leftright dark" :href="obj?.twitter" target="_blank">
                                    <span class="socicon socicon-twitter"></span>
                                </a>
                                <a v-if="obj?.instagram" title="Instagram" class="socicon_a  link-underline-leftright dark" :href="obj?.instagram" target="_blank">
                                    <span class="socicon socicon-instagram"></span>
                                </a>
                            </div>
                            <div class="map">
                                <div class="map-map" v-html="googleMapsImageLink"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
//state
import { mapState } from 'vuex'
import gh from '@/helpers/gh'
import gsap, {Power3} from 'gsap'
export default {
    name: "Popup",
    data() {
        return {
            window:window,
            json:null,
            googleMapApiKey:'AIzaSyCVJSFxTi3keceORWk6BLBRWtAa2Fa-aRo',
            googleMapZoom:19
        }
    },
    computed: {
        ...mapState({
            popupOpen: state => state.popupOpen,
            obj: state => state.popupObject,
        }),
        addressString() {
            let obj = this.obj
            return `${obj.address1}+${obj.address2}+${obj.city},${obj.state}+${obj.zip}`
        },
        addressLink() {
            let obj = this.obj
            return `https://www.google.com/maps/search/${obj.address1}+${obj.address2}+${obj.city},${obj.state}+${obj.zip}`
        },
        googleMapsImageLink() {
            //TO ADD MARKER: &markers=size:mid|color:0xff0000|label:|${this.addressString}
            return `<a class="generic-image-hover" target="_blank" href="${this.addressLink}"><img src="https://maps.googleapis.com/maps/api/staticmap?center=${this.addressString}&zoom=${this.googleMapZoom}&scale=2&size=400x400&maptype=roadmap&key=${this.googleMapApiKey}&format=jpg&visual_refresh=true&markers=size:mid|color:0xff0000|label:|${this.addressString}"></a>`
        },
    },
    watch: {
        popupOpen() {
            if (this.popupOpen) {
                this.reveal()
            } else {
                this.unreveal()
            }
        }
    },
    mounted() {
        window.addEventListener('keyup', this.detectEscapeKey)
    },
    methods: {
        detectEscapeKey(e) {
            if (e.keyCode === 27) {
                this.closePopup()
            }
        },
        reveal() {
            gh.enableBodyScroll(false)
            let tl = gsap.timeline({});
            tl.fromTo('#popup-inner', 1,
                {
                    left: '100%',
                    opacity:0
                },
                {
                    left: '0%',
                    opacity:1,
                    ease: Power3.easeOut
                },
                0
            )
        },
        closePopup() {
            this.unreveal()
        },
        unreveal() {
            let tl = gsap.timeline({});
            tl.fromTo('#popup-inner', 0.5,
                {
                    left: 0,
                    opacity:1
                },
                {
                    left: '-100%',
                    opacity:0,
                    ease: Power3.easeIn,
                    onComplete:this.unreveal2
                },
                0
            )

        },
        unreveal2() {
            this.$store.commit('closePopup')
            gh.enableBodyScroll(true)
        },
    }
}
</script>