<template>
    <div class="logo-marquee" data-aos="custom-fade-in">
        <div v-if="showMarquee" class="capper">
            <div class="marquee-wrapper">
                <div id="marquee-inner">
                    <div
                        v-for="(item, index) in locationsArray"
                        :key="`iconmarquee-${index}-${index}`"
                        class="icon"
                        @click="openPopup(cloneDeep(item))"
                    >
                        <div class="bg">
                            <div class="bgimg" :style="`background-image: url(${item.icon})`"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { cloneDeep, shuffle } from 'lodash'
import gsap from 'gsap'
export default {
    name: "LogoMarquee",
    data () {
        return {
            window: window,
            json: null,
            locationsArray: [],
            cloneDeep:cloneDeep,
            shuffle:shuffle,
            showMarquee:false
        }
    },
    mounted() {
        axios.get((`${window.config.apiroot}locations${window.config.apiext}`)).then((res) => {
            this.json = res.data
            //get all locations, shuffle
            let arr = []
            for (let i=0; i < this.json.sections.length;i++) {
                const section = this.json.sections[i]
                for (let ii=0; ii < section.items.length;ii++) {
                    const item = cloneDeep(section.items[ii])
                    arr.push(item)
                }
            }
            this.locationsArray = shuffle(arr)
            for (let i=0;i<10;i++) {
                this.locationsArray = this.locationsArray.concat(shuffle(arr))
            }
            this.showMarquee = true
            this.$nextTick(() => {
                this.runMarquee()
            })

        }).catch(() => {})
    },
    methods: {
        openPopup (obj) {
            this.$store.commit('openPopup', obj)
        },
        runMarquee() {
            gsap.fromTo("#marquee-inner",
                {
                    x: 0
                },
                {
                    x: '-100%',
                    duration: 0.5*this.locationsArray.length,
                    repeat: -1
                }
            )
        }
    }
}
</script>
