<template>
    <div v-show="show" id="transition-overlay-outer">
        <div id="transition-overlay">
            <div style="opacity: 0" id="transition-overlay-image">
                <img src="@/assets/images/global/i5.svg"/>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import gsap, { Power3 } from 'gsap'
    import gh from '@/helpers/gh'
    export default {
        name: "TransitionOverlay",
        data() {
            return {
                window:window,
                show:true,
                tme:1.0,
                didOnce:false,
            }
        },
        computed: {
            ...mapState({
                triggerTransitionOverlay: state => state.triggerTransitionOverlay,
                routerNext: state => state.routerNext,
            }),
        },
        watch: {
            triggerTransitionOverlay() {
                if (this.triggerTransitionOverlay) {
                    this.reveal()
                }
            }
        },
        methods: {
            reveal() {
                this.show = true
                let tl = gsap.timeline({});
                if (this.didOnce) {
                    tl.fromTo('#transition-overlay', this.tme / 2,
                        { x: '100%' },
                        { x: 0, ease: Power3.easeOut },
                        0
                    )
                } else {
                    tl.fromTo('#transition-overlay', this.tme / 2,
                        { x: 0 },
                        { x: 0, ease: Power3.easeOut },
                        0
                    )
                }

                tl.fromTo('#transition-overlay-image',this.tme/1.5,
                    {scale:1.4,opacity:0},
                    {scale:1,opacity:1,ease:Power3.easeOut,onComplete:this.reveal2},
                    this.tme/3
                )
            },
            reveal2() {
                this.routerNext()
                setTimeout(this.reveal3,250)
            },
            reveal3() {
                let tl = gsap.timeline({});
                tl.fromTo('#transition-overlay-image',0.75,
                    {scale:1,opacity:1},
                    {scale:0,opacity:0,ease:Power3.easeOut},
                    0
                )
                tl.fromTo('#transition-overlay', this.tme / 2,
                    { x: 0 },
                    { x: '-100%', ease: Power3.easeOut,onComplete:this.reveal4 },
                    this.tme/3
                )
            },
            reveal4() {
                this.show = false
                this.didOnce = true
                this.window.config.routerInTransition = false
                gh.globalSectionInit()
            }
        }
    }
</script>