<template>
    <div class="block block-featured-slides">
        <div v-if="json" class="swiper-holder">
            <div :id="swiperContainerId" class="swiper-container" data-aos="custom-fade-in">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"
                         v-for="(item, index) in json"
                         :key="`featured-${index}-${Math.random()}`"
                    >
                        <FeaturedSlidesSlide :item="item"/>
                    </div>
                </div>
                <!--
                <div class="swiper-button swiper-button-prev">
                    PREV
                </div>
                <div class="swiper-button swiper-button-next">
                    NEXT
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import FeaturedSlidesSlide from '@/components/blocks/FeaturedSlidesSlide'
import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
import 'swiper/swiper-bundle.min.css'

Swiper.use([Navigation, Pagination, Autoplay])
//import gsap, {Power3} from 'gsap'

export default {
    name: "FeaturedSlides",
    components:{
        FeaturedSlidesSlide
    },
    data () {
        return {
            slider: null,
            swiperContainerId: null,
            swiperContainerSel: null
        }
    },
    props: {
        json: {
            type: Array,
            required: true,
            default: null,
        },
    },
    mounted () {
        this.swiperContainerId = 'fs-' + Date.now()
        this.swiperContainerSel = '#' + this.swiperContainerId
        this.init()
    },
    methods: {
        init () {
            this.slider = new Swiper(this.swiperContainerSel, {
                init: false,
                speed: 500,
                //effect: 'coverflow',
                grabCursor: true,
                centeredSlides: true,
                loop: true,
                slidesPerView: 1,
                preventClicks: false,
                preventClicksPropagation: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
                autoplay: {
                    delay: 5000,
                },
            })
            /*
            this.slider.on('slideChangeTransitionStart', function () {
                let tl = gsap.timeline({});
                tl.fromTo(this.swiperContainerSel + ' .captions', 0.3,
                    { opacity: 1 },
                    { opacity: 0, ease: Power3.easeIn },
                    0
                )
            }.bind(this))
            this.slider.on('slideChangeTransitionEnd', function () {
                this.realIndex = this.slider.realIndex
                let tl = gsap.timeline({});
                tl.fromTo(this.swiperContainerSel + ' .captions', 0.3,
                    { opacity: 0 },
                    { opacity: 1, ease: Power3.easeOut },
                    0
                )
            }.bind(this))

             */
            setTimeout(this.sliderInit2, 100)
        },
        sliderInit2 () {
            this.slider.init()
        }
    }
}
</script>