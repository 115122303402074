<template>
    <div class="swiper-slide-inner">
        <div class="bgimg" :style="item.imgWithStyle"></div>
        <div class="panel-capper capper">
            <div v-if="item.panel" class="panel">
                <div class="panel-inner">
                    <router-link
                        v-if="item.href && item.target==='_self'"
                        :to="item.href"
                        class="generic-image-hover"
                    >
                        <img :src="item.panel"/>
                    </router-link>
                    <a class="generic-image-hover" v-else-if="item.href && item.target!=='_self'" :href="item.href" :target="item.target">
                        <img :src="item.panel"/>
                    </a>
                    <img v-else :src="item.panel"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FeaturedSlidesSlide",
    props:{
        item: {
            type: Object,
            required: true,
        },
    }
}
</script>