<template>
    <section v-if="json" class="page-home">
        <FeaturedSlides :json="json.feature.slides"></FeaturedSlides>
        <GenericText
            bgColor="bg-purple-dk"
            :json="json.about"
        ></GenericText>
        <Locations></Locations>
        <CTAs :json="json.ctas"></CTAs>
        <LogoMarquee></LogoMarquee>
    </section>
</template>
<script>
import axios from 'axios'
import FeaturedSlides from '@/components/blocks/FeaturedSlides'
import GenericText from '@/components/blocks/GenericText'
import Locations from '@/components/blocks/Locations'
import CTAs from '@/components/blocks/CTAs'
import LogoMarquee from "@/components/blocks/LogoMarquee";

export default {
    name: "Home",
    components: {
        LogoMarquee,
        FeaturedSlides,
        GenericText,
        Locations,
        CTAs
    },
    data () {
        return {
            window: window,
            json: null,
            inPanelTransition: false,
        }
    },
    created () {

    },
    mounted () {
        axios.get((`${window.config.apiroot}home${window.config.apiext}`))
            .then((res) => {
                this.json = res.data
                this.reveal()
            }).catch(() => {
        })
        this.reveal()
    },
    methods: {
        reveal () {
            if (this.inPanelTransition === false) {
                this.inPanelTransition = true
            }
        },
    }
}
</script>