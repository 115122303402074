<template>
    <section v-if="json" class="page-promos">
        <FeaturedSimple
            :featuredImage="json.featuredImage"
            :title="json.title"
        ></FeaturedSimple>
        <div class="capper-padded">
            <div class="copy rte" data-aos="custom-fade-in" v-html="json.body"></div>
        </div>
        <div v-if="json.promos" class="capper">
            <div class="capper-padded">
                <div class="deals">
                    <div :class="`deal ${item.imgSize}`"
                         v-for="(item, index) in json.promos"
                         :key="`deal-${index}-${Math.random()}`"
                         data-aos="custom-fade-in"
                    >
                        <img v-if="!item.href" :src="item.img"/>
                        <router-link v-else-if="item.target==='_self'" class="generic-image-hover" :to="item.href">
                            <img :src="item.img"/>
                        </router-link>
                        <a v-else class="generic-image-hover" :href="item.href" :target="item.target">
                            <img :src="item.img"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import gh from '@/helpers/gh'
import FeaturedSimple from "@/components/blocks/FeaturedSimple";
export default {
    name: "Deals",
    components: {
        FeaturedSimple,
    },
    data () {
        return {
            window: window,
            json: null,
            transition: false,
        }

    },
    created () {
    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        axios.get((`${window.config.apiroot}promos${window.config.apiext}/?slug=${ slug }`))
            .then((res) => {
                this.json = res.data
                this.reveal()
            }).catch((e) => {
                console.error('Error',slug,e)
        })
        this.reveal()
    },
    methods: {
        reveal () {
            if (this.inPanelTransition === false) {
                this.inPanelTransition = true
            }
        },
        unreveal () {

        },
    }
}
</script>