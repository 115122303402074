<template>
    <div id="header-container">
        <header v-if="json" :class="(smallHeader) ? 'small' : ''">
            <div class="capper">
                <div class="capper-padded">
                    <div class="flex-content">
                        <div class="l">
                            <router-link
                                to="/"
                            >
                                <img class="generic-image-hover" src="@/assets/images/global/logo.png"/>
                            </router-link>
                        </div>
                        <div class="c" v-show="json.header?.nav">
                            <nav>
                                <ul>
                                    <li
                                        v-for="(item, index) in json.header.nav"
                                        :key="`nav-${index}`"
                                    >
                                        <Link
                                            :href="item.href"
                                            :target="item.target"
                                            :label="item.label"
                                            classes="link-underline-leftright white small"
                                        />
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="r" v-show="json.header?.cta">
                            <div @click="setMobileNavOpen(false)">
                                <router-link
                                    v-if="json.header?.cta.target === '_self'"
                                    :to="json.header?.cta.href">
                                    <button class="btn" ><span>{{json.header?.cta.label}}</span></button>
                                </router-link>
                                <a v-else :href="json.header?.cta.href" :target="json.header?.cta.target">
                                    <button class="btn" ><span>{{json.header?.cta.label}}</span></button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div id="mobile-nav-hamburger">
            <button :class="['hamburger hamburger--spin', (mobileNavOpen) ? 'is-active' : '']" @click="setMobileNavToggle" type="button">
                <span class="hamburger-box"><span class="hamburger-inner"></span></span>
            </button>
        </div>
        <div v-if="json"  id="mobile-nav-overlay-outer">
            <div id="mobile-nav-overlay" :class="(mobileNavOpen) ? '' : 'hidden'">
                <nav>
                    <ul>
                        <li
                            v-for="(item, index) in json.header.nav"
                            :key="`mobilenav-${index}`"
                        >
                            <div @click="setMobileNavOpen(false)">
                                <Link
                                    :href="item.href"
                                    :target="item.target"
                                    :label="item.label"
                                    classes="link-underline-leftright white small"
                                />
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div id="header-pusher" :class="(smallHeader) ? 'small' : ''"></div>
    </div>
</template>
<script>
//state
import { mapState } from 'vuex'
import Link from "@/components/pieces/Link";
export default {
    name: "Header",
    components: {
        Link,
    },
    data() {
        return {
            window:window,
            json:null,
            isSmallHeader:false,
            mobileNavOpen:false
        }
    },
    computed: {
        ...mapState({
            smallHeader: state => state.smallHeader,
        })
    },
    watch: {
        smallHeader() {
            this.isSmallHeader = this.smallHeader
        }
    },
    mounted() {
        this.json = this.window.config.json.global
        window.addEventListener('scroll', this.scroll)
    },
    methods: {
        scroll() {
            this.$store.commit('setSmallHeader',(window.scrollY>0))
        },
        setMobileNavToggle() {
            this.mobileNavOpen = !this.mobileNavOpen
        },
        setMobileNavOpen(setOpen) {
            this.mobileNavOpen=setOpen
        }
    }
}
</script>