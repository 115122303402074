import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'

const routerHistory = createWebHistory()
const router = createRouter({
    history: routerHistory,
    routes: [], //defined globally in main
    scrollBehavior () {
        //scroll to top on all page changes
        return { top: 0 }
    }
})
router.beforeEach((to, from, next) => {
    //first page load
    if (window.config.routerInTransition) {
        return false
    } else {
        window.config.routerInTransition = true
        if (window.config.loadedOnce === false) {
            //let metaTitle = to.meta.title
            //metaTitle += (metaTitle) ? ' - The Oasis on 5' : 'The Oasis on 5'
            //document.title = metaTitle
            window.config.loadedOnce = true
            store.commit('routerNext', next)
            setTimeout(function () {
                store.commit('triggerTransitionOverlay', true)
            }, 500)
        } else {
            store.commit('routerNext', next)
            store.commit('triggerTransitionOverlay', true)
        }
    }
})
router.afterEach((to) => {
    //set new title after gate
    let metaTitle = to.meta.title
    metaTitle += (metaTitle) ? ' - Tejon Ranch Commerce Center - The Oasis on I-5' : 'Tejon Ranch Commerce Center - The Oasis on I-5'
    document.title = metaTitle
})
export default router